<template>
  <!-- 认证审核过程 -->
  <app-container :title="'实名认证'" :background="tool.getThemeList(theme)">
    <div class="auth_step">
      <div class="waited">
        <img src="../../assets/image/auth/icon_auth_one.png" alt="" />
      </div>
      <div class="waited">您的实名已经被驳回</div>
      <div class="reason">驳回原因:{{ msg }}</div>
      <button class="desc_button" @click="returnPage">重新提交</button>
    </div>
  </app-container>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      msg: "",
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.msg = route.query.msg || "";
    const methods = {
      returnPage() {
        router.push({
          name: "personal-authUpLoad"
        });
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.auth_step {
  height: 400px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .waited {
    text-align: center;
    color: #333333;
    font: {
      size: 26px;
      weight: 500;
    }
    img {
      width: 201px;
      height: 190px;
    }
  }
  .reason {
    text-align: center;
    color: #333333;
    font: {
      size: 26px;
      weight: 500;
    }
    width: 500px;
    white-space: nowrap;
    overflow-x: auto;
  }
  .desc_button {
    width: 690px;
    height: 84px;
    color: #ffffff;
    border: none;
    @include background_color("background_color1");
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
    margin: 20px auto 0px;
    text-align: center;
    line-height: 84px;
  }
}
</style>
